import React, { useState, useEffect, forwardRef, Ref } from "react";

import { bg2 } from "./Asset";

const DataHero = [
  {
    id: 1,
    text: "Web Developer",
    description:
      "I am an experienced web developer with expertise in creating innovative and responsive digital solutions.",
  },

  {
    id: 3,
    text: "Front-End Developer",
    description:
      "I am a dedicated Frontend Developer, ready to help you create amazing and immersive user experiences.",
  },
  {
    id: 3,
    text: "CMS Strapi",
    description:
      "Strapi's key features include a customizable Admin Panel, GraphQL and REST API support, the ability to work with various types of databases, and an authentication and authorization system. Strapi also supports the use of plugins and extensions, allowing developers to add additional functionality according to project needs. As a highly user-friendly and easy-to-use CMS, Strapi is a popular choice among developers for managing content in modern web projects.",
  },
];

const Hero = forwardRef((_, ref: Ref<HTMLDivElement>) => {
  const [currentText, setCurrentText] = useState("");
  const [currentDescription, setCurrentDescription] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [typingDirection, setTypingDirection] = useState("right");

  useEffect(() => {
    const isMobile = window.innerWidth <= 768; // Adjust the breakpoint as needed

    const intervalId = setInterval(() => {
      setCurrentText((prevText) => {
        const targetText = DataHero[currentIndex].text;
        const targetDescription = DataHero[currentIndex].description;

        let newDescription = "";

        if (typingDirection === "right") {
          if (prevText.length < targetText.length) {
            newDescription = targetText.slice(0, prevText.length + 1);
          } else {
            setTypingDirection("left");
          }
        } else {
          if (prevText.length > 0) {
            newDescription = targetText.slice(0, prevText.length - 1);
          } else {
            setTypingDirection("right");
            setCurrentIndex((prevIndex) => (prevIndex + 1) % DataHero.length);
          }
        }

        // Limit the description based on screen size
        const characterLimit = isMobile ? 100 : 100;
        const limitedDescription = targetDescription.slice(0, characterLimit);

        setCurrentDescription(
          typingDirection === "right" ? limitedDescription : ""
        );

        return newDescription;
      });
    }, 300);

    return () => clearInterval(intervalId);
  }, [currentIndex, typingDirection, currentText]);

  return (
    <div ref={ref} className=" w-full relative">
      <img src={bg2} alt="bg" className=" h-auto " />
      <div className="text-white absolute text-left sm:ml-[160px] ml-[30px] sm:mt-[-500px] mt-[-175px]">
        <h1>Hello, I'm a</h1>
        <p className="sm:text-[50px] text-[20px] font-bold">{currentText}|</p>
        <p className="sm:w-[340px] h-[70px] w-[200px] text-[12px] sm:text-[18px] sm:leading-6 sm:text-justify">
          {currentDescription}
          <span className="text-blue-500"> see...</span>
        </p>
        <div className="flex sm:gap-5">
          <button className="bg-slate-800 hover:bg-white rounded-lg sm:mt-[50px]">
            <h1 className="sm:text-[25px] text-[12px] px-[10px] hover:text-black">
              HIRE ME
            </h1>
          </button>
          <button className="bg-slate-800 hover:bg-white rounded-lg sm:mt-[50px]">
            <a
              href="https://drive.google.com/file/d/10yL-v8uyRYp85uu3_Xn7ytrSl4ll_m52/view?usp=sharing"
              className="sm:text-[25px] text-[12px] px-[10px] hover:text-black"
            >
              GET CV
            </a>
          </button>
        </div>
      </div>
    </div>
  );
});

export default Hero;
