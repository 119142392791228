export const majidvektor = require("./majidvektor.png");
export const profil = require("./profil.jpg");
export const bg = require("./wallpapers.jpg");
export const bg2 = require("./bg2.jpg");
export const Javascript = require("./Javascript.jpg");
export const Javascript2 = require("./Javascript2.jpg");
export const UI = require("./UI.jpg");
export const UX = require("./UX.jpg");
export const web1 = require("./web1.JPG");
export const web2 = require("./web2.JPG");
export const web3 = require("./web3.JPG");
export const pc = require("./pc.jpg");
export const android = require("./android.png");
export const WhatsApp = require("./whatsapp.png");
export const logo = require("./logomajid.png");
export const aruna = require("./aruna.JPG");
export const java = require("./Java.png");
export const dev = require("./dev.png");
export const strapi = require("./Strapi.png");
export const user = require("./User.png");
