import React, { Ref, forwardRef } from "react";
import { profil } from "./Asset";
import Skill from "./Skill";
import Courses from "./Courses";

const Resume = forwardRef((_, ref: Ref<HTMLDivElement>) => {
  return (
    <div ref={ref} className=" sm:h-[500px] sm:mt-[200px] mt-[20px] mx-[20px]">
      <div className="text-[30px] sm:text-[40px] font-bold text-center">
        <h1>Resume</h1>
      </div>
      <div className="sm:m-[20px] mt-[20px] sm:mt-0 sm:flex sm:ml-[100px] text-left">
        <div className="">
          <div className=" ml-[120px] mb-5 sm:ml-0  ">
            <img
              src={profil}
              alt="profil"
              className="sm:h-[300px] h-[150px] rounded-full "
            />
            <p className="sm:ml-[350px] ml-[-30px] sm:absolute text-[25px] sm:mt-[-160px] mt-4 sm:text-[40px] font-bold">
              ABDUL MAJID
            </p>
          </div>
          <div className="mt-[630px] sm:mt-0  ">
            <Skill />
          </div>
        </div>
        <div className="absolute  sm:mt-10 mt-[-770px]">
          <div className="sm:ml-[300px] sm:mt-[250px]  mt-[20px] ">
            <h1 className="sm:text-[28px] text-[24px] font-bold">About</h1>
            <div className="sm:text-justify text-[18px] shadow-xl radius-[20px] sm:p-[20px] p-[10px] sm:w-[800px] w-[350px] bg-slate-100 rounded-md">
              <p className="mb-[10px]">
                I am a Web Programmer with a focus on Front-End. I am
                experienced and proficient in web development using Javascript,
                Node.Js, React.Js, Typescript and TailwindCss. I'm used to using
                gitlab, strapi, and postman. Currently I have completed several
                real projects. using React Typescript, TailwindCss, and strapi.
              </p>
              <p>
                I am looking for a job opportunity as a Front-End Developer.
                Please Contact me via Abdulma272@gmail.com
              </p>
            </div>
          </div>
          <div className="sm:ml-[300px] sm:mt-[50px] mt-[20px]">
            <h1 className="sm:text-[28px] text-[24px] font-bold">Education</h1>
            <div className="sm:text-justify text-[18px] shadow-xl radius-[20px] sm:p-[20px] p-[10px] sm:w-[800px] w-[350px] bg-slate-100 rounded-md">
              <li className="mb-[10px]">
                Universitas Bina Sarana Informatika Karawang (2021 - Now)
                Program Studi (D3) Sistem Informasi
              </li>
              <li>
                SMAN 1 Pedes (2017 - 2020) Jurusan Matematika Dan Ilmu
                Pengetahuan Alam
              </li>
            </div>
          </div>
        </div>
      </div>
      <Courses />
    </div>
  );
});

export default Resume;
