import { Ref, forwardRef } from "react";
import { android, dev, strapi } from "./Asset";

const ServiceData = [
  {
    id: 1,
    image: strapi,
    title: "CMS Strapi",
    text: "Strapi's key features include a customizable Admin Panel, GraphQL and REST API support, the ability to work with various types of databases, and an authentication and authorization system. Strapi also supports the use of plugins and extensions, allowing developers to add additional functionality according to project needs. As a highly user-friendly and easy-to-use CMS, Strapi is a popular choice among developers for managing content in modern web projects.",
  },
  {
    id: 2,
    image: dev,
    title: "Web Developer",
    text: " Latest web development services, using leading technologies such as React with TypeScript, Tailwind CSS for elegant design, and Strapi CMS for efficient content management, we bring you an extraordinary business experience.",
  },
  {
    id: 3,
    image: android,
    title: "Frontend Developer",
    text: "Frontend Developer I use React Js, TailwindCss, Typescript. Responsive design and ready to be integrated in the Backend by optimizing performance, & updating the interface. Design expertise, maintenance & technology updates are key. so user experience & ensure easy access to app/web.",
  },

  // Add more items as needed
];

const Service = forwardRef((_, ref: Ref<HTMLDivElement>) => {
  return (
    <div ref={ref} className="sm:mt-[530px] mt-[250px] mx-[30px] sm:mx-0">
      <h1 className="text-center font-bold sm:mb-[40px] text-[35px]">
        Service
      </h1>
      <div className="grid sm:grid-cols-3 sm:mx-[100px] mt-[10px] sm:mt-0 gap-7">
        {ServiceData.map((data, index) => (
          <div
            key={index}
            className="hover:bg-slate-300 sm:shadow-xl bg-slate-50 p-[20px]   rounded-md"
          >
            <div className="border-[3px] border-black rounded-full h-[100px] w-[100px] ml-[90px] sm:ml-[120px] ">
              <img
                src={data.image}
                alt="service"
                className=" p-[10px] h-[100px]   object-contain "
              />
            </div>
            <h1 className="text-center text-[20px] sm:text-[30px] mt-[10px] sm:mt-0 font-bold">
              {data.title}
            </h1>
            <p className="text-[15px] text-justify sm:text-center">
              {data.text &&
                (data.text.length > 200
                  ? data.text.slice(0, 200) + "..."
                  : data.text)}
              <span className="text-blue-500">Lihat Selengkapnya</span>
            </p>
          </div>
        ))}
      </div>
    </div>
  );
});

export default Service;
