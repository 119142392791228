import React, { useState, useEffect, forwardRef, Ref } from "react";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import SosialContact from "./SosialContact";

const Contact = forwardRef((_, ref: Ref<HTMLDivElement>) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",

    message: "",
  });

  const [showAlert, setShowAlert] = useState(false);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Check if any form field is empty
    if (Object.values(formData).some((value) => !value.trim())) {
      Swal.fire({
        title: "Incomplete Form",
        text: "Please fill in all fields",
        icon: "warning",
      });
      return;
    }

    // Check if email is a valid email address
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      Swal.fire({
        title: "Invalid Email",
        text: "Please enter a valid email address",
        icon: "error",
      });
      return;
    }

    // Display a confirmation message and handle WhatsApp connection
    Swal.fire({
      title: "Connect on WhatsApp?",
      text: "Do you want to connect on WhatsApp?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, connect!",
      cancelButtonText: "No, thanks",
    }).then((result) => {
      if (result.isConfirmed) {
        const { name, email, message } = formData;

        // Simulate connecting to WhatsApp with a pre-populated message
        const whatsappMessage = `Hi, I'm ${name}
email : ${email}
Message: ${message}`;

        // Simulate redirection to a WhatsApp URL with the pre-populated message
        const whatsappUrl = `https://wa.me/6281574633093?text=${encodeURIComponent(
          whatsappMessage
        )}`;

        // Open WhatsApp URL in a new tab
        window.open(whatsappUrl);

        // Display success message
        Swal.fire({
          title: "Connecting to WhatsApp...",
          icon: "success",
        });
      }
    });
  };

  useEffect(() => {
    if (showAlert) {
      // Trigger SweetAlert2 here
      // You can customize this alert as needed
      Swal.fire({
        title: "Additional Alert",
        text: "This alert is triggered separately.",
        icon: "info",
      });

      // Reset showAlert to false after showing the alert
      setShowAlert(false);
    }
  }, [showAlert]);

  return (
    <div ref={ref} className="container w-full pt-[100px]">
      <div className="text-[30px] text-center font-bold ">Contact</div>
      <div className="sm:flex sm:mx-[100px] ">
        <form
          onSubmit={handleSubmit}
          className="sm:w-[700px] sm:px-[50px] bg-white p-8 shadow-md rounded-md"
        >
          <div className="mb-4">
            <label
              htmlFor="name"
              className="block text-gray-700 text-[20px] font-bold mb-2"
            >
              Full Name
            </label>
            <input
              type="text"
              placeholder="Enter Full Name..."
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
              required
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-gray-700 text-[20px] font-bold mb-2"
            >
              Email
            </label>
            <input
              type="email"
              placeholder="Masukan Email..."
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
              required
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="message"
              className="block text-gray-700 text-[20px] font-bold mb-2"
            >
              Message
            </label>
            <textarea
              id="message"
              placeholder="Enter Message..."
              name="message"
              value={formData.message}
              onChange={handleChange}
              rows={4}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
              required
            />
          </div>

          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue active:bg-blue-800"
          >
            Kirim
          </button>
        </form>
        <SosialContact />
      </div>
    </div>
  );
});

export default Contact;
