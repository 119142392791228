import {
  faSquareFacebook,
  faSquareInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SosialContact = () => {
  return (
    <div className="sm:ml-[50px]  text-black sm:mb-[50px]  p-[30px]  opacity-50 rounded-md">
      <div className="sm:mt-[10px]    ">
        <FontAwesomeIcon
          icon={faLocationDot}
          className="pr-[10px] text-[25px] sm:mt-[10px] "
        />
        <button>
          <div>
            <h1 className="text-[20px] font-bold text-left">Address</h1>
            <p className="text-[15px] font-bold">
              Karawang, Jawa Barat - Indonesia
            </p>
          </div>
        </button>
      </div>
      <div className="flex sm:mt-[10px]  text-left   ">
        <FontAwesomeIcon
          icon={faEnvelope}
          className="pr-[10px] text-[25px] sm:mt-[10px] "
        />
        <button>
          <div>
            <h1 className="text-[20px] font-bold text-left">Email</h1>
            <a
              href="mailto:Abdulma272@gmail.com"
              className="text-[15px] font-bold"
            >
              Abdulma272@gmail.com
            </a>
          </div>
        </button>
      </div>
      <div className=" mt-[10px]  text-left">
        <h1 className="text-[20px] font-bold">Socials Media</h1>
        <div className=" text-[25px]">
          <button className="flex">
            <FontAwesomeIcon
              icon={faSquareInstagram}
              className="mt-[10px] pr-[10px]"
            />
            <a
              href="https://www.instagram.com/abd_majid23/"
              className="text-[18px] font-bold mt-[8px]"
            >
              Instagram
            </a>
          </button>
        </div>
        <div className=" text-[25px]">
          <button className="flex">
            <FontAwesomeIcon
              icon={faSquareFacebook}
              className="mt-[10px]  pr-[10px]"
            />
            <a
              href="https://www.facebook.com/majidpoetra.litrt"
              className="text-[18px] font-bold mt-[8px]"
            >
              Facebook
            </a>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SosialContact;
