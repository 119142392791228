import React, { Ref, forwardRef, useState } from "react";
import { aruna } from "./Asset";

const portfolioData = [
  {
    id: 1,
    image: aruna,
    text: "RUMAH ARUNA",
    url: "https://rumaharuna.sch.id/",
  },

  // Add more items as needed
];

const Portfolio = forwardRef((_, ref: Ref<HTMLDivElement>) => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  return (
    <section
      ref={ref}
      className="sm:mt-[740px] mb-[30px]  items-center w-full mt-[50px]  "
    >
      <div className="sm:mb-[50px]">
        <h1 className="sm:text-4xl text-[30px]  font-bold text-center">
          Portofolio
        </h1>
      </div>
      <div className="grid sm:grid-cols-2 sm:mx-[100px] mx-[30px]   gap-12 mt-5 ">
        {portfolioData.map((item, index) => (
          <div
            key={index}
            className="relative"
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
          >
            <img
              src={item.image}
              alt={`Image `}
              className=" object-contain rounded-md"
            />
            {hoveredIndex === index && (
              <div className="absolute  top-[0px] rounded-md left-0 w-full h-full   bg-black bg-opacity-40   text-lg">
                <div className=" sm:justify-center  text-center sm:mt-[50px] bg-gray-400 bg-opacity-70   rounded-lg sm:p-5">
                  <p className="mt-[50px] sm:mb-5 mb-1 sm:text-[30px] font-bold ">
                    {item.text}
                  </p>
                  <a
                    href={item.url}
                    className="sm:text-[20px] sm:mt-[30px] bg-blue-700 px-[10px] py-[5px] rounded-lg"
                  >
                    see website
                  </a>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </section>
  );
});

export default Portfolio;
