// Navbar.js
import React, { useState, useEffect, RefObject } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faTimes,
  faCaretDown,
  faCaretUp,
} from "@fortawesome/free-solid-svg-icons";
import { logo } from "./Asset";

interface NavbarProps {
  sections: {
    Hero: RefObject<HTMLElement>;
    Resume: RefObject<HTMLElement>;
    Service: RefObject<HTMLElement>;
    Portofolio: RefObject<HTMLElement>;
    Testimonials: RefObject<HTMLElement>;
    Contact: RefObject<HTMLElement>;
  };
}

const Navbar: React.FC<NavbarProps> = ({ sections }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isServicesOpen, setServicesOpen] = useState(false);
  const [isScrolled, setScrolled] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const toggleServices = () => {
    setServicesOpen(!isServicesOpen);
  };

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    setScrolled(scrollTop > 0);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToSection = (ref: RefObject<HTMLElement>) => {
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: "smooth",
      });
      setMenuOpen(false);
    }
  };

  return (
    <nav
      className={`bg-gray-900 px-[20px] sm:px-[40px] pt-[20px] z-10 ${
        isScrolled ? "fixed top-0 w-full shadow-lg" : ""
      }`}
    >
      <div className="sm:flex container mx-auto">
        <div className="flex justify-between items-center">
          <div
            className="text-white font-bold text-xl mb-4 flex"
            onClick={() => scrollToSection(sections.Hero)}
          >
            <img src={logo} alt="image" className=" w-[80px] object-contain" />
            <h1 className="mt-[10px]">Abdul Majid</h1>
          </div>

          <div className="sm:hidden mt-[-15px] mr-[20px]">
            <FontAwesomeIcon
              icon={isMenuOpen ? faTimes : faBars}
              className="text-white cursor-pointer text-2xl"
              onClick={toggleMenu}
            />
          </div>
        </div>

        {/* Navigation Links */}
        <div
          className={`${
            isMenuOpen ? "block" : "hidden"
          } sm:flex ml-[20px]  sm:ml-[600px] sm:mt-[10px] sm:gap-12 sm:text-[20px] text-[18px] `}
        >
          <div
            className="mb-3 sm:mb-4"
            onClick={() => scrollToSection(sections.Hero)}
          >
            <a href="#" className="text-white hover:text-blue-300 ">
              Home
            </a>
          </div>
          <div
            className="mb-3 sm:mb-4"
            onClick={() => scrollToSection(sections.Resume)}
          >
            <a href="#" className="text-white mb-2 hover:text-blue-300 sm:mb-4">
              Resume
            </a>
          </div>
          <div className="group relative mb-3 sm:mb-4 z-10 ">
            <div className="flex items-center" onClick={toggleServices}>
              <a href="#" className="text-white hover:text-blue-300">
                Projects
              </a>
              <FontAwesomeIcon
                icon={isServicesOpen ? faCaretUp : faCaretDown}
                className="text-white ml-2 cursor-pointer"
              />
            </div>
            <div
              className={`absolute ${
                isServicesOpen ? "block" : "hidden"
              } bg-gray-700 mt-2 py-2 w-[150px] rounded-md sm:ml-[-28px]  `}
            >
              <a
                href="#"
                className="text-white block px-3 hover:bg-blue-50 hover:text-black rounded-sm"
                onClick={() => scrollToSection(sections.Service)}
              >
                Service
              </a>
              <a
                href="#"
                className="text-white block px-3  hover:bg-blue-50 hover:text-black rounded-sm"
                onClick={() => scrollToSection(sections.Portofolio)}
              >
                Portofolio
              </a>
              <a
                href="#"
                className="text-white block px-3  hover:bg-blue-50 hover:text-black rounded-sm"
                onClick={() => scrollToSection(sections.Testimonials)}
              >
                Testimonials
              </a>
            </div>
          </div>
          <div
            className="pb-5 sm:pb-4"
            onClick={() => scrollToSection(sections.Contact)}
          >
            <a
              href="#"
              className="text-white mb-2 sm:mb-4 hover:text-blue-300 sm:rounded-sm"
            >
              Contact
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
