import React from "react";

const Footer = () => {
  return (
    <div className="bg-slate-700 text-center text-[20px] sm:py-[10px] text-white sm:h-[50px] sm:mt-[50px]">
      <h1>CopyRight 2023/ Abdul Majid</h1>
    </div>
  );
};

export default Footer;
