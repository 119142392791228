import React from "react";

const skillsData = [
  {
    title: "Frontend Skills",
    skills: ["HTML", "TailwindCss", "React.Js", "Typescript", "Node.Js"],
  },
  {
    title: "Software Proficiency",
    skills: ["Figma", "MySQL", "PostgreSQL", "Postman", "Strapi"],
  },
];

const TechnicalSkills = () => {
  return (
    <div className="grid grid-cols-2  sm:grid-rows sm:block gap-20 sm:gap-2 mr-[70px] ">
      {skillsData.map((section, index) => (
        <div
          key={index}
          className="text-justify pl-[-20px]  sm:text-[18px] text-[12px] shadow-xl radius-[10px] sm:p-[20px] p-[10px] sm:w-[250px] w-[170px] sm:mb-[20px] bg-slate-100 rounded-md"
        >
          <h1 className="sm:text-[20px] text-[14px] underline underline-offset-8 font-bold">
            {section.title}
          </h1>
          <ul className="list-inside list-disc mt-[10px]">
            {section.skills.map((skill, skillIndex) => (
              <li key={skillIndex}>{skill}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default TechnicalSkills;
