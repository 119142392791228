import React, { useRef } from "react";
import Footer from "./Component/Footer";
import Contact from "./Component/Contact";
import Hero from "./Component/Hero";
import Resume from "./Component/Resume";
import Service from "./Component/Service";
import Portofolio from "./Component/Portofolio";
import Testimonials from "./Component/Testimoanials";
import Navbar from "./Component/Navbar";

const App = () => {
  const heroRef = useRef(null);
  const resumeRef = useRef(null);
  const serviceRef = useRef(null);
  const portfolioRef = useRef(null);
  const testimonialsRef = useRef(null);
  const contactRef = useRef(null);

  return (
    <div className="App">
      <Navbar
        sections={{
          Hero: heroRef,
          Resume: resumeRef,
          Service: serviceRef,
          Portofolio: portfolioRef,
          Testimonials: testimonialsRef,
          Contact: contactRef,
        }}
      />
      <div className="App">
        <Hero ref={heroRef} />
        <Resume ref={resumeRef} />
        <Service ref={serviceRef} />
        <Portofolio ref={portfolioRef} />
        <Testimonials ref={testimonialsRef} />
        <Contact ref={contactRef} />
        <Footer />
      </div>
    </div>
  );
};

export default App;
