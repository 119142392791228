import React, { useState, useEffect } from "react";
import { Javascript, UI } from "./Asset";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Coursesdata = [
  {
    id: 1,
    image: Javascript,
    Judul: "CERTIFICATE FULLSTACK JAVASCRIPT",
    instansi: "SALT ACADEMY JAKARTA",
    tgl: "November 2022 - February 2023",
  },
  {
    id: 2,
    image: UI,
    Judul: "CERTIFICATE UI/UX Desainner",
    instansi: "Kelas.com",
    tgl: "November 2022 - February 2023",
  },
];

const Courses = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % Coursesdata.length);
  };

  const prevSlide = () => {
    setCurrentSlide(
      (prevSlide) => (prevSlide - 1 + Coursesdata.length) % Coursesdata.length
    );
  };

  useEffect(() => {
    // Automatically move to the next slide every 3 seconds
    const intervalId = setInterval(() => {
      nextSlide();
    }, 3000);

    return () => {
      // Cleanup the interval on component unmount
      clearInterval(intervalId);
    };
  }, [currentSlide]);

  return (
    <div className="sm:mt-[650px] mt-[30px] sm:pb-[100px] sm:mx-[100px] mx-[30px]">
      <div>
        <h1 className="font-bold text-[25px] sm:text-[30px] text-center">
          Courses & Certifications
        </h1>
      </div>
      <div className="sm:flex sm:mx-[200px] mx-[10px] mt-[20px] text-center relative">
        {Coursesdata.map((data, index) => (
          <div
            key={data.id}
            className={`${
              index === currentSlide ? "opacity-100" : "opacity-0"
            } transition-opacity duration-500 absolute left-0 top-0`}
          >
            <div className="sm:mt-[30px]  bg-slate-200 rounded-xl ">
              {data.image && (
                <img
                  src={data.image}
                  alt="Image"
                  className="sm:h-[500px] h-[190px] object-contain sm:p-[20px]"
                />
              )}
              <div className="absolute sm:w-[690px] h-[70px]  w-[270px] opacity-80 rounded-md bg-slate-300 sm:h-[150px] mt-[-70px] sm:mt-[-150px]">
                <p className=" sm:text-[20px] text-[10px] font-bold">
                  {data.Judul}
                </p>
                <p className="sm:text-[20px] text-[15px] font-bold">
                  {data.instansi}
                </p>
                <p className="sm:text-[15px] text-[10px] font-bold">
                  {data.tgl}
                </p>
              </div>
            </div>
          </div>
        ))}
        <button
          className="absolute sm:ml-[-100px] ml-[-155px] sm:text-[40px] text-[30px] sm:mt-[200px] mt-[70px]"
          onClick={prevSlide}
        >
          <FontAwesomeIcon
            icon={faChevronLeft}
            className="text-blue-200 hover:text-blue-500"
          />
        </button>
        <button
          className="absolute right-0 sm:mr-[-100px] mr-[-20px] text-[30px] sm:text-[40px] sm:mt-[200px] mt-[70px]"
          onClick={nextSlide}
        >
          <FontAwesomeIcon
            icon={faChevronRight}
            className="text-blue-200 hover:text-blue-500"
          />
        </button>
      </div>
    </div>
  );
};

export default Courses;
