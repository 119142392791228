// Testimonials.js
import {
  forwardRef,
  Ref,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { user } from "./Asset";

const testimonialsData = [
  {
    id: 1,
    name: "Crinstians",
    description:
      "He did exactly what I asked for, and at a very good pace. Highly recommended. Good job, my friend, keep it up.",
    image: user,
    rating: 5,
  },
  {
    id: 2,
    name: "John Doe",
    description:
      "Very professional and very quick work, thank you again Majid.",
    image: user,
    rating: 5,
  },
  {
    id: 3,
    name: "Samuel",
    description:
      "He was able to understand our requirements for the project and implemented them well. The quality of his work is extremely good and the code is well structured and manageable.",
    image: user,
    rating: 5,
  },
  {
    id: 4,
    name: "Sharah",
    description:
      "Very responsive communicator, and happy to make the smallest change, without a fuss! When they say unlimited revisions, they mean it!.",
    image: user,
    rating: 5,
  },
];

const Testimonials = forwardRef((_, ref: Ref<HTMLDivElement>) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [autoScroll, setAutoScroll] = useState(true);

  // Create a ref for the CSSTransition node
  const nodeRef = useRef(null);

  const shiftTestimonials = useCallback(
    (shiftAmount: number) => {
      const newIndex =
        (currentIndex + shiftAmount + testimonialsData.length) %
        testimonialsData.length;
      setCurrentIndex(newIndex);
    },
    [currentIndex]
  );

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    const autoScrollHandler = () => {
      shiftTestimonials(1);
    };

    if (autoScroll) {
      intervalId = setInterval(autoScrollHandler, 5000);
    }

    return () => clearInterval(intervalId);
  }, [autoScroll, currentIndex, shiftTestimonials]);

  const handleMouseEnter = () => {
    setAutoScroll(false);
  };

  const handleMouseLeave = () => {
    setAutoScroll(true);
  };

  return (
    <div
      ref={ref}
      className="relative overflow-hidden testimonials-container py-[100px] sm:mx-[100px] "
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="absolute mt-[-20px] text-center w-full">
        <h1 className="text-[30px] font-bold mt-[-50px] text-black">
          Testimonials
        </h1>
      </div>
      <style>
        {`
          .testimonials-container {
            display: flex;
            overflow: hidden;
          }

          .testimonials-inner-container {
            display: flex;
            transition: transform 0.5s ease-in-out;
          }

          .testimonial-item {
            flex: 0 0 400px;
          }

          .testimonial-item-enter {
            transform: translateX(100%);
            opacity: 0;
          }

          .testimonial-item-enter-active,
          .testimonial-item-exit {
            transform: translateX(0);
            opacity: 1;
          }

          .testimonial-item-exit-active {
            transform: translateX(-100%);
            opacity: 0;
          }
        `}
      </style>
      <TransitionGroup component={null}>
        <CSSTransition
          key={testimonialsData[currentIndex].id}
          timeout={500}
          classNames="testimonial-item"
          nodeRef={nodeRef}
        >
          <div ref={nodeRef} className="testimonials-inner-container">
            {[...Array(3)].map((_, index) => {
              const testimonial =
                testimonialsData[
                  (currentIndex + index) % testimonialsData.length
                ];

              return (
                <div
                  key={testimonial.id}
                  className="w-[400px] p-5 testimonial-item"
                >
                  <div className="bg-white p-6 rounded-lg shadow-2xl">
                    <div className="h-[150px]">{testimonial.description}</div>
                    <div className="flex">
                      <img
                        src={testimonial.image}
                        alt={testimonial.name}
                        className="w-12 h-12 rounded-full object-cover mb-4"
                      />
                      <div className="ml-[10px]">
                        <h3 className="text-lg font-semibold">
                          {testimonial.name}
                        </h3>
                        {[...Array(5)].map((_, starIndex) => (
                          <FontAwesomeIcon
                            key={starIndex}
                            icon={faStar}
                            className={`text-${
                              starIndex < testimonial.rating ? "yellow" : "gray"
                            }-500`}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </CSSTransition>
      </TransitionGroup>
      <div className="flex justify-between absolute inset-y-0 left-[20px]  right-[-30px]">
        <button
          onClick={() => shiftTestimonials(-1)}
          className="sm:hover:bg-slate-500 sm:bg-slate-400  sm:opacity-20  hover:bg-opacity-30 sm:w-[360px] w-[200px] my-[120px] rounded-lg text-2xl focus:outline-none"
        />

        <button
          onClick={() => shiftTestimonials(1)}
          className="sm:hover:bg-slate-500 sm:bg-slate-400  sm:opacity-20  hover:bg-opacity-30 w-[360px] my-[120px] rounded-lg text-2xl focus:outline-none"
        />
      </div>
    </div>
  );
});

export default Testimonials;
